<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />
    <div class="content">
      <div class="page-title pt-0 pb-3">
        <a href="javascript:;" @click="back" class="text-dark">
          <i class="icon-arrow-left52 mr-1"></i>
          <span class="font-weight-semibold">Kembali</span>
        </a>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-3">
              <v-select placeholder=" -- Pilih Type PPA -- " class="vs__multiple" v-model="filterPPA"  :clearable="true" :options="filterPPAData" @input="filteringPPA()" label="text" :reduce="v=>v.value">
              </v-select>
            </div>
          </div>
        </div>

        <div class="card-header">
          <div class="d-flex align-items-center justify-content-between">
            <div class="form-group mb-0">
              <button @click="apiGet()" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
                class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.left><i class="icon-spinner11"></i></button>
            </div>

            <div>
              <a href="javascript:;" @click="initbulkVerif()" v-if="moduleRole('verifikasi_cppt') && (selectedInput||[]).length" class="btn btn-success btn-labeled btn-labeled-left">
                <b><i class="icon-file-check2"></i></b>
                <span>Verifikasi CPPT Terpilih</span>
              </a>
            </div>

            <div>
              <a v-if="moduleRole('tambah_cppt_dokter')"  @click="addNewCPPT('DOKTER')" href="javascript:;" class="btn btn-info btn-labeled btn-labeled-left mr-2">
                <b><i class="icon-plus2"></i></b>
                <span>Tambah CPPT Dokter</span>
              </a>
              
              <a v-if="moduleRole('tambah_cppt')"  @click="addNewCPPT('PERAWAT')" href="javascript:;" class="btn btn-info btn-labeled btn-labeled-left mr-2">
                <b><i class="icon-plus2"></i></b>
                <span>Tambah CPPT SOAP</span>
              </a>
              
              <a v-if="moduleRole('tambah_cppt_sbar') && isShowSBAR"  @click="addNewCPPT('SBAR')" href="javascript:;" class="btn btn-info btn-labeled btn-labeled-left mr-2">
                <b><i class="icon-plus2"></i></b>
                <span>Tambah CPPT SBAR</span>
              </a>
            </div>
          </div>
        </div>
        <div class="table-responsive mb-0">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th v-if="(user.id == rowReg.aranr_dokter_id) || (user.levelId == 1)">
                  <b-form-checkbox
                    @change="checkData($event,true)"
                    v-model="checkAll"
                    :value="true"
                    :unchecked-value="false"
                    id="check-all" 
                    name="check-all-id"
                  >
                  </b-form-checkbox>
                </th>
                <th>Asesmen</th>
                <th>Instruksi</th>
                <!--  -->
                <th :colspan="(user.id == rowReg.aranr_dokter_id || user.levelId == 1) ?1:2">Verifikasi</th>
                <th width="150">Aksi</th>
              </tr>
            </thead>
            <template v-for="(v,k) in (listCPPT||[])">
              <tbody v-if="v.arancp_type == 'PERAWAT'" :key="k">
                <tr class="table-info">
                  <td colspan="4">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <div class="font-weight-semibold mr-2">{{v.data.arancpp_tanggal | moment("DD MMMM YYYY")}}, 
                        {{v.data.arancpp_jam}}</div>
                        <small class="text-secondary">PPA: {{getUserInput(v.arancp_type)||v.ppa}} - {{v.bu_full_name||"-"}}</small>
                      </div>
                      <div v-if="v.arancp_is_draft != 'Y' && moduleRole('verifikasi_cppt') && v.arancp_is_verif != 'Y'">
                        <a href="javascript:;" @click="initsingleVerif(v)" class="btn btn-icon btn-sm btn-success ml-2" data-popup="tooltip" title="" data-original-title="Verifikasi CPPT">
                          <i class="icon-file-check2"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td rowspan="5">
                    <span v-if="v.arancp_is_sbar_ugd == 'Y'"> - </span>
                    <template v-else>
                      <template v-if="(v.arancp_created_by == user.id || v.bu_level == user.levelId || user.levelId == 1)">
                      <a href="javascript:;" @click="toEdit(v,'RanapCPPTSOAP')" v-b-tooltip.hover title="Edit CPPT" class="btn btn-icon btn-sm btn-info ml-2"><i class="icon-pencil"></i></a>

                      <a href="javascript:;" @click="deleteCPPT(v)" v-if="v.arancp_is_draft=='Y' && (v.arancp_created_by == user.id || v.bu_level == user.levelId || user.levelId == 1)" v-b-tooltip.hover title="Hapus CPPT" class="btn btn-icon btn-sm btn-danger ml-2"><i class="icon-bin"></i></a>
                      </template>
                      <span v-else> - </span>
                    </template>
                  </td>
                </tr>
                <tr>
                  <th rowspan="4" v-if="(user.id == rowReg.aranr_dokter_id) || (user.levelId == 1)">
                    <b-form-checkbox v-if="v.arancp_is_verif != 'Y'" :disabled="v.arancp_is_draft == 'Y'" v-model="v.selected" 
                    @change="checkDataRow()"
                    :name="'check-all'+k"
                    :id="'check-all-id'+k"
                    :value="true" :unchecked-value="false">
                    </b-form-checkbox>
                  </th>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>S</h2>
                        </div>
                      </div>
                      <div class="col">
                        <pre class="pre-input">{{v.data.arancpp_subjektif||"-"}}</pre>
                      </div>
                    </div>
                  </td>
                  <td class="align-top" rowspan="4">-</td>
                  <td class="align-top" rowspan="4">
                    <template v-if="v.arancp_is_draft == 'Y'">
                      <span class="badge badge-warning">Draft</span>
                    </template>
                    <template v-else>
                      <span v-if="v.arancp_is_verif != 'Y'" class="badge badge-warning">Perlu Verifikasi DPJP</span>
                      <div v-else>
                        <span class="badge badge-success">Sudah Diverifikasi</span><br/>
                        <span>
                           Diverifikasi oleh <b>{{v.verif_by}}</b> Pada tanggal {{ v.arancp_verif_date | moment("DD MMMM YYYY, HH:mm")}} WIB
                        </span>
                      </div>
                     
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>O</h2>
                        </div>
                      </div>
                      <div class="col">
                        <pre class="pre-input">{{v.data.arancpp_objektif||"-"}}</pre>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>A</h2>
                        </div>
                      </div>
                      <div class="col">
                        <pre class="pre-input">{{v.data.arancpp_assesment||"-"}}</pre>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>P</h2>
                        </div>
                      </div>
                      <div class="col">
                        <pre class="pre-input">{{v.data.arancpp_planning||"-"}}</pre>
                        <template v-if="v.data.arancpp_is_resep == 'Y'">
                        <div class="font-weight-semibold">Resep</div>
                        <ul class="mb-0 pl-3">
                          <li v-for="(v1,k1) in (v.resep||[])" :key="k1">
                            {{v1.arancpak_nama||"-"}} - {{v1.arancpak_jumlah||0}} - {{v1.arancpak_satuan}} - 
                            <span v-if="v1.arancpak_frekuensi == 99999">
                              {{v1.arancpak_frekuensi_lainnya||"-"}}
                            </span>
                            <span v-else>{{v1.mdo_name||"-"}}</span>
                          </li>
                        </ul>
                        <span v-if="!(v.resep||[]).length"></span>
                        <div class="font-weight-semibold">Catatan</div>
                        <span>{{v.data.arancpp_keterangan||"-"}}</span>
                        </template>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              
              <tbody v-if="v.arancp_type == 'SBAR'" :key="k">
                <tr class="table-info">
                  <td colspan="4">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <div class="font-weight-semibold mr-2">{{v.data.arancps_tanggal | moment("DD MMMM YYYY")}}, 
                        {{v.data.arancps_jam}}</div>
                        <small class="text-secondary">PPA: {{getUserInput(v.arancp_type)||v.ppa}} - {{v.bu_full_name||"-"}}</small>
                      </div>
                      <div v-if="v.arancp_is_draft != 'Y' && moduleRole('verifikasi_cppt') && v.arancp_is_verif != 'Y'">
                        <a href="javascript:;" @click="initsingleVerif(v)" class="btn btn-icon btn-sm btn-success ml-2" data-popup="tooltip" title="" data-original-title="Verifikasi CPPT">
                          <i class="icon-file-check2"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td rowspan="5">
                    <span v-if="v.arancp_is_sbar_ugd == 'Y'"> - </span>
                    <template v-else>
                      <template v-if="(v.arancp_created_by == user.id || v.bu_level == user.levelId || user.levelId == 1)">
                      <a href="javascript:;" @click="toEdit(v,'RanapCPPTSBAR')" v-b-tooltip.hover title="Edit CPPT" class="btn btn-icon btn-sm btn-info ml-2"><i class="icon-pencil"></i></a>

                      <a href="javascript:;" @click="deleteCPPT(v)" v-if="v.arancp_is_draft=='Y' && (v.arancp_created_by == user.id || v.bu_level == user.levelId || user.levelId == 1)" v-b-tooltip.hover title="Hapus CPPT" class="btn btn-icon btn-sm btn-danger ml-2"><i class="icon-bin"></i></a>
                      </template>
                      <span v-else> - </span>
                    </template>
                  </td>
                </tr>
                <tr>
                  <th rowspan="4" v-if="(user.id == rowReg.aranr_dokter_id) || (user.levelId == 1)">
                    <b-form-checkbox v-if="v.arancp_is_verif != 'Y'" :disabled="v.arancp_is_draft == 'Y'" v-model="v.selected" 
                    @change="checkDataRow()"
                    :name="'check-all'+k"
                    :id="'check-all-id'+k"
                    :value="true" :unchecked-value="false">
                    </b-form-checkbox>
                  </th>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>S</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div class="req-list d-flex align-items-center" v-if="v.data.arancps_laporan_nilai_kritis == 'Y' && v.data.arancps_laporan_kondisi_pasien == 'Y'">
                          <div v-if="v.data.arancps_laporan_nilai_kritis == 'Y'">
                              <i
                              class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <b class="align-middle font-weight-semibold">Pelaporan Nilai Kritis</b>
                          </div>
                          <div v-if="v.data.arancps_laporan_kondisi_pasien == 'Y'" class="ml-2">
                              <i
                              class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <b class="align-middle font-weight-semibold">Pelaporan Kondisi Pasien</b>
                          </div>
                        </div>
                        <p>
                          Melaporkan Pasien ke DPJP <br />
                          Pasien dengan diagnosa medis <span class="font-weight-semibold">{{v.data.arancps_diagnosa_medis||"-"}}</span>, keluhan utama saat ini <span class="font-weight-semibold">{{v.data.arancps_keluhan_utama||"-"}}</span>. Pasien mempunyai riwayat penyakit: <span class="font-weight-semibold">{{v.data.arancps_riwayat_penyakit||"-"}}</span>.
                        </p>
                      </div>
                    </div>
                  </td>
                  <td class="align-top" rowspan="4">
                    <div class="mb-2" v-for="(vA,kA) in (v.advis||[])" :key="kA">
                      <div class="font-weight-semibold">Advis {{kA+1}}: </div>
                      <pre class="pre-input">{{vA.arancsa_advis_text||"-"}}</pre>       
                      
                      <p class="text-danger-800">Perintah Dokter telah dilakukan penulisan, pembacaan perintah ulang dan konfirmasi ke {{vA.nama_dokter||"-"}} oleh <span class="font-weight-semibold">
                      {{v.data.arancps_nama_petugas||"-"}}</span>, Pada tanggal <span class="font-weight-semibold">
                      {{v.data.arancps_tanggal | moment('DD MMMM YYYY')}}
                      </span> jam <span class="font-weight-semibold">{{v.data.arancps_jam}} WIB</span></p>      

                      <a href="javascript:;" v-if="isHaveTindakLanjut(vA)" @click="toDetailSBARAdvis(vA)" data-toggle="modal" class="font-weight-semibold text-underline">Lihat Selengkapnya</a>
                    </div>

                  </td>
                  <td class="align-top" rowspan="4">
                    <template v-if="v.arancp_is_draft == 'Y'">
                      <span class="badge badge-warning">Draft</span>
                    </template>
                    <template v-else>
                      <span v-if="v.arancp_is_verif != 'Y'" class="badge badge-warning">Perlu Verifikasi DPJP</span>
                      <div v-else>
                        <span class="badge badge-success">Sudah Diverifikasi</span><br/>
                        <span>
                           Diverifikasi oleh <b>{{v.verif_by}}</b> Pada tanggal {{ v.arancp_verif_date | moment("DD MMMM YYYY, HH:mm")}} WIB
                        </span>
                      </div>
                     
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>B</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <template v-if="(v.data.arancps_riwayat_alergi||[]).length">
                            <span>Pasien mempunyai riwayat alergi:</span>
                            <ul class="mb-0 pl-3">
                              <li v-for="(vAl,kAl) in (v.data.arancps_riwayat_alergi||[])" :key="kAl">
                                ({{vAl.jenis||"-"}}) {{vAl.name}}
                              </li>
                            </ul>
                          </template>
                          dengan keluhan saat ini <span class="font-weight-semibold">{{v.data.arancps_keluhan_saat_ini||"-"}}</span>, pengobatan yang sudah dilakukan <span class="font-weight-semibold">{{v.data.arancps_pengobatan_yang_dilakukan||"-"}}</span>, riwayat pembedahan <span class="font-weight-semibold">{{v.data.arancps_riwayat_pembedahan||"-"}}</span> riwayat tindakan invasif <span class="font-weight-semibold">

                          <ol class="mb-0" style="margin-left: -35px;" v-if="(v.data.arancps_tindakan_invasif||[]).length">
                            <li v-for="(v,k) in (v.data.arancps_tindakan_invasif||[])" :key="k" class="mt-1 ml-3">
                                {{v}}
                            </li>
                          </ol>
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>A</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <div class="mb-2">
                            <!--
                            <div>{{v.data.arancps_nilai_nyeri}} | 

                              <template v-if="(row.ap_usia > 6)">
                                  <span class="border-left ml-2 pl-2 font-weight-semibold" v-if="v.data.arancps_nilai_nyeri == 0">Tidak Ada Nyeri</span>
                                  <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.data.arancps_nilai_nyeri >= 1 && v.data.arancps_nilai_nyeri <= 3">Sedikit Nyeri</span>
                                  <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.data.arancps_nilai_nyeri >= 4 && v.data.arancps_nilai_nyeri <= 5">Agak Mengganggu</span>
                                  <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.data.arancps_nilai_nyeri >= 6 && v.data.arancps_nilai_nyeri <= 7">Mengganggu Aktivitas</span>
                                  <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.data.arancps_nilai_nyeri >= 8 && v.data.arancps_nilai_nyeri <= 9">Sangat Mengganggu</span>
                                  <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.data.arancps_nilai_nyeri >= 10">Tak Tertahankan</span>
                              </template>
                              
                              <template v-if="(row.ap_usia <= 6)">
                                  <template v-if="(row.ap_usia > 1)">
                                      <span v-if="v.data.arancps_nilai_nyeri == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                      <span v-else-if="v.data.arancps_nilai_nyeri >= 1 && v.data.arancps_nilai_nyeri < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                                      <span v-else-if="v.data.arancps_nilai_nyeri >= 4 && v.data.arancps_nilai_nyeri < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                      <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                  </template>

                                  <template v-if="(row.ap_usia <= 1)">
                                      <span v-if="v.data.arancps_nilai_nyeri < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                      <span v-else-if="v.data.arancps_nilai_nyeri >= 3 && v.data.arancps_nilai_nyeri < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                      <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                                  </template>
                              </template>
                            </div>,
                            -->
                            Tanda tanda vital sebagai berikut:
                            <ul class="mb-0 pl-3">
                              <li v-if="v.data.arancps_ttv_tekanan_darah_min && v.data.arancps_ttv_tekanan_darah_max">Tekanan Darah: 
                              <span>
                              {{v.data.arancps_ttv_tekanan_darah_min||"-"}}/
                              {{v.data.arancps_ttv_tekanan_darah_max||"-"}} mmHG
                              </span>
                              </li>

                              <li v-if="v.data.arancps_ttv_nadi && v.data.arancps_ttv_label">Nadi: 
                              <span>
                              {{v.data.arancps_ttv_nadi||"-"}} x/mnt - {{v.data.arancps_ttv_label||"-"}}
                              </span>
                              </li>

                              <li v-if="v.data.arancps_ttv_gula_darah">Gula Darah: 
                              <span>
                              {{v.data.arancps_ttv_gula_darah||"-"}} mg/dL
                              </span>
                              </li>
                              <li v-if="v.data.arancps_ttv_pernafasan">Pernafasan: 
                              <span>
                              {{v.data.arancps_ttv_pernafasan||"-"}} x/mnt
                              </span>
                              </li>
                              <li v-if="v.data.arancps_ttv_spo2">SPO2: 
                              <span>
                              {{v.data.arancps_ttv_spo2||"-"}}%
                              </span>
                              </li>
                              
                              <li v-if="v.data.arancps_ttv_suhu">Suhu: 
                              <span>
                              {{v.data.arancps_ttv_suhu||"-"}}C
                              </span>
                              </li>
                              
                              <li v-if="v.data.arancps_ttv_weight">Berat Badan 
                              <span>
                              {{v.data.arancps_ttv_weight||"-"}} kg
                              </span>
                              </li>

                              <li v-if="v.data.arancps_ttv_height">Tinggi: 
                              <span>
                              {{v.data.arancps_ttv_height||"-"}} cm
                              </span>
                              </li>

                              <li v-if="v.data.arancps_ttv_lingkar_kepala">Lingkar Kepala: 
                              <span>
                              {{v.data.arancps_ttv_lingkar_kepala||"-"}} cm
                              </span>
                              </li>

                              <li v-if="v.data.arancps_ttv_luas_permukaan_anak">Lingkar Dada: 
                              <span>
                              {{v.data.arancps_ttv_luas_permukaan_anak||"-"}} CM<sup>2</sup>
                              </span>
                              </li>

                              <li>Kesadaran: 
                              <span>{{v.data.arancps_ttv_kesadaran}} |     
                                  <span v-if="v.data.arancps_ttv_kesadaran >= 14"> Composmentis</span> 
                                  <span v-else-if="v.data.arancps_ttv_kesadaran >= 12 && v.data.arancps_ttv_kesadaran <= 13">Apatis</span>
                                  <span v-else-if="v.data.arancps_ttv_kesadaran >= 10 && v.data.arancps_ttv_kesadaran <= 11">Delirium</span>
                                  <span v-else-if="v.data.arancps_ttv_kesadaran >= 7 && v.data.arancps_ttv_kesadaran <= 9">Somonolen</span>
                                  <span v-else-if="v.data.arancps_ttv_kesadaran >= 5 && v.data.arancps_ttv_kesadaran <= 6">Sopor</span>
                                  <span v-else-if="v.data.arancps_ttv_kesadaran >= 4 && v.data.arancps_ttv_kesadaran <= 4">Semi-Comma</span>
                                  <span v-else>Coma</span>
                                  
                                  <span class="ml-2" style="margin-right:20px;">E: 
                                      {{v.data.arancps_ttv_kesadaran_e||0}} 
                                  </span>
                                  <span style="margin-right:20px;">M: 
                                      {{v.data.arancps_ttv_kesadaran_m||0}}  
                                  </span>
                                  <span style="margin-right:20px;">V: 
                                      {{v.data.arancps_ttv_kesadaran_v||0}}
                                  </span>
                              </span>
                              </li>
                            </ul>
                          </div>
                          <div class="mb-2" v-if="(v.data.arancps_hasil_lab||[]).length">
                            <span class="font-weight-semibold">Tindakan Lab</span>
                            <ol class="mb-0 pl-3">
                              <li v-for="(vL,kL) in (v.data.arancps_hasil_lab||[])" :key="kL">
                                {{vL.value||"-"}}
                              </li>
                            </ol>
                          </div>
                          <div class="mb-2" v-if="(v.data.arancps_hasil_radiologi||[]).length">
                            <span class="font-weight-semibold">Tindakan Radiologi</span>
                            <ol class="mb-0 pl-3">
                              <li v-for="(vR,kR) in (v.data.arancps_hasil_radiologi||[])" :key="kR">
                                {{vR.value||"-"}}
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>R</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <span class="font-weight-semibold">Usulan kolaborasi</span>
                          <ul class="mb-0 pl-3">
                            <li v-for="(vk,kk) in (v.data.arancps_usulan_kolaborasi||[])" :key="kk+'Usulan'">
                              {{vk||"-"}}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>

              <tbody v-if="v.arancp_type == 'DOKTER'" :key="k">
                <tr class="table-info">
                  <td colspan="4">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <div class="font-weight-semibold mr-2">{{v.data.arancpd_tanggal | moment("DD MMMM YYYY")}}, 
                        {{v.data.arancpd_jam}}</div>
                        <small class="text-secondary">PPA: {{getUserInput(v.arancp_type)||v.ppas}} - {{v.bu_full_name||"-"}}</small>
                      </div>
                      <div v-if="v.arancp_is_draft != 'Y' && moduleRole('verifikasi_cppt') && v.arancp_is_verif != 'Y'">
                        <a href="javascript:;" @click="initsingleVerif(v)" class="btn btn-icon btn-sm btn-success ml-2" data-popup="tooltip" title="" data-original-title="Verifikasi CPPT">
                          <i class="icon-file-check2"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td rowspan="5">
                    <span v-if="v.arancp_is_sbar_ugd == 'Y'"> - </span>
                    <template v-else>
                      <template v-if="(v.arancp_created_by == user.id || v.bu_level == user.levelId || user.levelId == 1)">
                      <a href="javascript:;" @click="toEdit(v,'RanapCPPTDokter')" v-b-tooltip.hover title="Edit CPPT" class="btn btn-icon btn-sm btn-info ml-2"><i class="icon-pencil"></i></a>

                      <a href="javascript:;" @click="deleteCPPT(v)" v-if="v.arancp_is_draft=='Y' && (v.arancp_created_by == user.id || v.bu_level == user.levelId || user.levelId == 1)" v-b-tooltip.hover title="Hapus CPPT" class="btn btn-icon btn-sm btn-danger ml-2"><i class="icon-bin"></i></a>
                      </template>
                      <span v-else> - </span>
                    </template>
                  </td>
                </tr>
                <tr>
                  <th rowspan="4" v-if="(user.id == rowReg.aranr_dokter_id) || (user.levelId == 1)">
                    <b-form-checkbox v-if="v.arancp_is_verif != 'Y'" :disabled="v.arancp_is_draft == 'Y'" v-model="v.selected" 
                    @change="checkDataRow()"
                    :name="'check-all'+k"
                    :id="'check-all-id'+k"
                    :value="true" :unchecked-value="false">
                    </b-form-checkbox>
                  </th>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>S</h2>
                        </div>
                      </div>
                      <div class="col">
                        <pre class="pre-input">{{v.data.arancpd_subjektif||"-"}}</pre>
                      </div>
                    </div>
                  </td>
                  <td class="align-top" rowspan="4">-</td>
                  <td class="align-top" rowspan="4">
                    <template v-if="v.arancp_is_draft == 'Y'">
                      <span class="badge badge-warning">Draft</span>
                    </template>
                    <template v-else>
                      <span v-if="v.arancp_is_verif != 'Y'" class="badge badge-warning">Perlu Verifikasi DPJP</span>
                      <div v-else>
                        <span class="badge badge-success">Sudah Diverifikasi</span><br/>
                        <span>
                           Diverifikasi oleh <b>{{v.verif_by}}</b> Pada tanggal {{ v.arancp_verif_date | moment("DD MMMM YYYY, HH:mm")}} WIB
                        </span>
                      </div>
                     
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>O</h2>
                        </div>
                      </div>
                      <div class="col">
                        <pre class="pre-input">{{v.data.arancpd_objektif||"-"}}</pre>
                        <p><span class="font-weight-semibold">Status Lokalis:</span> <br/>
                        <ul v-if="(v.data.arancpd_status_lokalis||[]).length" >
                          <li v-for="(vSl,kSl) in (v.data.arancpd_status_lokalis||[])" :key="kSl">
                            Terdapat {{(vSl.data||[]).length}} titik yang ditandai pada bagian {{defineAnat(vSl.type)}} pasien. 
                          </li>
                        </ul>
                        <span v-else> Tidak ada pemeriksaan </span>
                        <a href="javascript:;" @click="toDetailSBAR(v)" data-toggle="modal" class="font-weight-semibold text-underline">Lihat Selengkapnya</a></p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>A</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <div>
                            <div class="font-weight-semibold">Diagnosis:</div>   
                            <ul class="pl-3 mb-0" v-if="(v.diagnosa||[]).length">
                              <li v-for="(v1,k1) in (v.diagnosa||[])" :key="k1">
                                {{v1.mi10_name||"-"}}
                                <span class="font-weight-semibold">[{{v1.mi10_code||"-"}}]</span>
                              </li>
                            </ul>
                          </div>
                          <p>Diagnosa Lainnya: 
                          <pre class="pre-input">{{v.data.arancpd_diagnosa_text||"-"}}</pre>
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>P</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <pre class="pre-input">{{v.data.arancpd_perencanaan||"-"}}</pre>
                          <p>
                            <span class="font-weight-semibold">Tindak Lanjut: </span> {{checkTindakLanjut(v.data)}}
                            <a href="javascript:;" @click="toDetailSBAR(v)" data-toggle="modal" class="font-weight-semibold text-underline">Lihat Selengkapnya</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>

              <tbody v-if="v.arancp_type == 'PPARADIO' || v.arancp_type == 'PPALAB' || v.arancp_type == 'PPAFARMASI' || v.arancp_type == 'PPAFISIO'" :key="k">
                <tr class="table-info">
                  <td colspan="4">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <div class="font-weight-semibold mr-2">{{v.data.arancppa_tanggal | moment("DD MMMM YYYY")}}, 
                        {{v.data.arancppa_jam}}</div>
                        <small class="text-secondary">PPA: {{getUserInput(v.arancp_type)||v.ppa}} - {{v.bu_full_name||"-"}}</small>
                      </div>
                      <div v-if="v.arancp_is_draft != 'Y' && moduleRole('verifikasi_cppt') && v.arancp_is_verif != 'Y'">
                        <a href="javascript:;" @click="initsingleVerif(v)" class="btn btn-icon btn-sm btn-success ml-2" data-popup="tooltip" title="" data-original-title="Verifikasi CPPT">
                          <i class="icon-file-check2"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td rowspan="5">
                    <a href="javascript:;" v-if="v.arancp_type == 'PPARADIO'" @click="toEdit(v,'RanapCPPTPPARADIO')" v-b-tooltip.hover title="Edit CPPT" class="btn btn-icon btn-sm btn-info ml-2"><i class="icon-pencil"></i></a>

                    <a href="javascript:;" v-else-if="v.arancp_type == 'PPALAB'" @click="toEdit(v,'RanapCPPTPPALAB')" v-b-tooltip.hover title="Edit CPPT" class="btn btn-icon btn-sm btn-info ml-2"><i class="icon-pencil"></i></a>
                    
                    <a href="javascript:;" v-else-if="v.arancp_type == 'PPAFISO'" @click="toEdit(v,'RanapCPPTPPAFisio')" v-b-tooltip.hover title="Edit CPPT" class="btn btn-icon btn-sm btn-info ml-2"><i class="icon-pencil"></i></a>

                    <a href="javascript:;" v-else-if="v.arancp_type == 'PPAFARMASI'" @click="toEdit(v,'RanapCPPTPPAFarmasi')" v-b-tooltip.hover title="Edit CPPT" class="btn btn-icon btn-sm btn-info ml-2"><i class="icon-pencil"></i></a>

                    <a href="javascript:;" @click="deleteCPPT(v)" v-if="v.arancp_is_draft=='Y' && (v.arancp_created_by == user.id || v.bu_level == user.levelId || user.levelId == 1)" v-b-tooltip.hover title="Hapus CPPT" class="btn btn-icon btn-sm btn-danger ml-2"><i class="icon-bin"></i></a>
                  </td>
                </tr>
                <tr>
                  <th rowspan="4" v-if="(user.id == rowReg.aranr_dokter_id) || (user.levelId == 1)">
                    <b-form-checkbox v-if="v.arancp_is_verif != 'Y'" :disabled="v.arancp_is_draft == 'Y'" v-model="v.selected" 
                    @change="checkDataRow()"
                    :name="'check-all'+k"
                    :id="'check-all-id'+k"
                    :value="true" :unchecked-value="false">
                    </b-form-checkbox>
                  </th>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>S</h2>
                        </div>
                      </div>
                      <div class="col">
                        <p>{{v.data.arancppa_subjektif||"-"}}</p>
                      </div>
                    </div>
                  </td>
                  <td class="align-top" rowspan="4">-</td>
                  <td class="align-top" rowspan="4">
                    <template v-if="v.arancp_is_draft == 'Y'">
                      <span class="badge badge-warning">Draft</span>
                    </template>
                    <template v-else>
                      <span v-if="v.arancp_is_verif != 'Y'" class="badge badge-warning">Perlu Verifikasi DPJP</span>
                      <div v-else>
                        <span class="badge badge-success">Sudah Diverifikasi</span><br/>
                        <span>
                           Diverifikasi oleh <b>{{v.verif_by}}</b> Pada tanggal {{ v.arancp_verif_date | moment("DD MMMM YYYY, HH:mm")}} WIB
                        </span>
                      </div>
                     
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>O</h2>
                        </div>
                      </div>
                      <div class="col">
                        <p>{{v.data.arancppa_objektif||"-"}}</p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>A</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <p>{{v.data.arancppa_assesmen||"-"}}</p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>P</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <p>{{v.data.arancppa_planning||"-"}}</p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>

              <tbody v-if="v.arancp_type == 'DIETISIEN'" :key="k">
                <tr class="table-info">
                  <td colspan="4">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <div class="font-weight-semibold mr-2">{{v.data.arancpdi_tanggal | moment("DD MMMM YYYY")}}, 
                        {{v.data.arancpdi_jam}}</div>
                        <small class="text-secondary">PPA: {{getUserInput(v.arancp_type)||v.ppa}} - {{v.bu_full_name||"-"}}</small>
                      </div>
                      <div v-if="v.arancp_is_draft != 'Y' && moduleRole('verifikasi_cppt') && v.arancp_is_verif != 'Y'">
                        <a href="javascript:;" @click="initsingleVerif(v)" class="btn btn-icon btn-sm btn-success ml-2" data-popup="tooltip" title="" data-original-title="Verifikasi CPPT">
                          <i class="icon-file-check2"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td rowspan="6">
                    <a href="javascript:;" @click="toEdit(v,'RanapCPPTDietisien')" v-b-tooltip.hover title="Edit CPPT" class="btn btn-icon btn-sm btn-info ml-2"><i class="icon-pencil"></i></a>

                    <a href="javascript:;" @click="deleteCPPT(v)" v-if="v.arancp_is_draft=='Y' && (v.arancp_created_by == user.id || v.bu_level == user.levelId || user.levelId == 1)" v-b-tooltip.hover title="Hapus CPPT" class="btn btn-icon btn-sm btn-danger ml-2"><i class="icon-bin"></i></a>
                  </td>
                </tr>

                <tr>
                  <th rowspan="5" v-if="(user.id == rowReg.aranr_dokter_id) || (user.levelId == 1)">
                    <b-form-checkbox v-if="v.arancp_is_verif != 'Y'" :disabled="v.arancp_is_draft == 'Y'" v-model="v.selected" 
                    @change="checkDataRow()"
                    :name="'check-all'+k"
                    :id="'check-all-id'+k"
                    :value="true" :unchecked-value="false">
                    </b-form-checkbox>
                  </th>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>A</h2>
                        </div>
                      </div>
                      <div class="col">
                        <p>
                          <span class="font-weight-semibold">BB: </span>
                          <span v-if="v.data.arancpdi_bb">{{v.data.arancpdi_bb}}kg,</span>
                          <span v-else> - ,</span>
                          <span class="font-weight-semibold">TB: </span>
                          <span v-if="v.data.arancpdi_tb">{{v.data.arancpdi_tb}}cm,</span>
                          <span v-else> - ,</span>
                          <span class="font-weight-semibold">BBI: </span>                          
                          <span v-if="v.data.arancpdi_bbi">{{v.data.arancpdi_bbi}}kg,</span>
                          <span v-else> - ,</span>
                          <span class="font-weight-semibold">BBI%: </span>
                          <span v-if="v.data.arancpdi_bbi100">{{v.data.arancpdi_bbi100}}%,</span>
                          <span v-else> - ,</span>
                          <span class="font-weight-semibold">IMT: </span>
                          <span v-if="v.data.arancpdi_imt">{{v.data.arancpdi_imt}}kg/m<sup>2</sup>,</span>
                          <span v-else> - </span>
                        </p>
                        <p><span class="font-weight-semibold">Status Gizi: </span>{{v.data.arancpdi_status_gizi}}</p>
                        <p>
                        
                          <span class="font-weight-semibold">Gangguan Fisik/Klinis: </span>{{getConfigDynamic(Config.mr.yesNoOptV2,v.data.arancpdi_fisik_klinis)||"-"}}
                          
                          <ul class="mb-0 pl-3" v-if="v.data.arancpdi_fisik_klinis == 'Y'">
                            <li><span class="font-weight-semibold">Diare:</span> 
                            <span v-if="v.data.arancpdi_is_diare == 'Y'"> {{v.data.arancpdi_is_diare_text||"-"}}</span>
                            <span v-else> - </span>
                            </li>
                            <li><span class="font-weight-semibold">Gangguan Menelan: </span> 
                            <span v-if="v.data.arancpdi_is_lainnya == 'Y'"> {{v.data.arancpdi_is_lainnya_text||"-"}}</span>
                            <span v-else> - </span>
                            </li>
                            <li><span class="font-weight-semibold">Muntah: </span> 
                            <span v-if="v.data.arancpdi_is_muntah == 'Y'"> {{v.data.arancpdi_is_muntah_text||"-"}}</span>
                            <span v-else> - </span>
                            </li>
                            <li><span class="font-weight-semibold">Konstipasi: </span> 
                            <span v-if="v.data.arancpdi_is_konstipasi == 'Y'"> {{v.data.arancpdi_is_konstipasi_text||"-"}}</span>
                            <span v-else> - </span>
                            </li>
                            <li><span class="font-weight-semibold">Mual: </span> 
                            <span v-if="v.data.arancpdi_is_mual == 'Y'"> {{v.data.arancpdi_is_mual_text||"-"}}</span>
                            <span v-else> - </span>
                            </li>
                            <li><span class="font-weight-semibold">Gangguan Mengunyah: </span> 
                            <span v-if="v.data.arancpdi_is_gangguan_mengunyah == 'Y'"> {{v.data.arancpdi_is_gangguan_mengunyah_text||"-"}}</span>
                            <span v-else> - </span>
                            </li>
                            <li><span class="font-weight-semibold">Lainnya: </span> 
                            <span v-if="v.data.arancpdi_is_lainnya == 'Y'"> {{v.data.arancpdi_is_lainnya_text||"-"}}</span>
                            <span v-else> - </span>
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td class="align-top" rowspan="5">-</td>
                  <td class="align-top" rowspan="5">
                    <template v-if="v.arancp_is_draft == 'Y'">
                      <span class="badge badge-warning">Draft</span>
                    </template>
                    <template v-else>
                      <span v-if="v.arancp_is_verif != 'Y'" class="badge badge-warning">Perlu Verifikasi DPJP</span>
                      <div v-else>
                        <span class="badge badge-success">Sudah Diverifikasi</span><br/>
                        <span>
                           Diverifikasi oleh <b>{{v.verif_by}}</b> Pada tanggal {{ v.arancp_verif_date | moment("DD MMMM YYYY, HH:mm")}} WIB
                        </span>
                      </div>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>D</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <div class="font-weight-semibold">Diagnosis:</div>
                          <ul class="pl-3 mb-0" v-if="(v.data.arancpdi_diagnosis||[]).length">
                            <li v-for="(v,k) in (v.data.arancpdi_diagnosis||[])" :key="k">
                              {{v.id ? v.name : v.text}}
                            </li>
                          </ul>
                          <span v-else>Tidak ada data</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>I</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <div>
                            <div class="font-weight-semibold">Intervensi:</div>
                            <ul class="pl-3 mb-0" v-if="(v.data.arancpdi_intervensi||[]).length">
                            <li v-for="(v,k) in (v.data.arancpdi_intervensi||[])" :key="k">
                              {{v.id ? v.name : v.text}}
                            </li>
                          </ul>
                          <span v-else>Tidak ada data</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>M</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <p>{{v.data.arancpdi_monitoring||"-"}}</p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="form-row">
                      <div class="col-auto">
                        <div class="label_code label_code_sm m-0">
                          <h2>E</h2>
                        </div>
                      </div>
                      <div class="col">
                        <div>
                          <p>{{v.data.arancpdi_evaluasi||"-"}}</p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>

            </template>
            <template v-if="!(listCPPT||[]).length">
              <tbody>
                <tr class="table-info">
                  <td colspan="99" class="text-center">Tidak ada Data CPPT</td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
      </div>

      <b-modal v-model="openDetailSBAR" :title="'Detail CPPT Dokter'" size="xl" ok-only ok-title="Tutup">
        <DetailSBAR :rowData="dataSBAR"/>
      </b-modal> 

      <b-modal v-model="openDetailSBARAdvis" :title="'Detail CPPT SBAR Tindak Lanjut'" size="xl" ok-only ok-title="Tutup">
        <DetailSBARAdvis :rowData="dataSBARAdvis"/>
      </b-modal> 

      <b-modal v-model="openVerifFP" hide-header size="sm" ok-only ok-title="Mengerti">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <div class="wrap_ic_fg mb-3 d-inline-block">
                <b class="text-warning text-center d-block mt-2"><i style="font-size: 70px;" class="icon-point-up"></i></b>
              </div>
              <div class="mb-3">
                <h4 class="font-weight-semibold mb-0">Silakan letakkan ibu jari Anda</h4>
                <small class="text-info mb-2">*Pastikan Mesin FingerPrint Anda telah Terpasang</small>

                <div class="note_verifikasi bor_red" v-if="statusFP == 'fail'">
                  <p> <span class="text-secondary text-danger font-weight-semibold">Verifikasi CPPT gagal.</span> <br> <span>Data Anda tidak sesuai dengan data DPJP</span></p>
                </div>

                <div class="note_verifikasi bor_green" v-else-if="statusFP == 'success'">
                  <p> <span class="text-secondary text-success font-weight-semibold">Verifikasi CPPT berhasil dilakukan.</span></p>
                </div>

              </div>

              <div class="table-responsive" v-if="statusFP == 'success'">
                <table class="table table-sm table-striped">
                  <tbody>
                    <tr>
                      <td>
                      <div class="result_tab">
                          <h4>Nama</h4>
                        </div>
                      </td>
                      <td>
                        {{rowReg.bu_full_name||"-"}}
                      </td>
                      
                    </tr>
                    <tr>
                      <td>
                      <div class="result_tab">
                          <h4>NIK</h4>
                        </div>
                      </td>
                      <td>
                        {{rowReg.bu_nik||"-"}}
                      </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
        
    </div>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  import PatientInfo from '@/components/Ranap/PatientInfo.vue'
  import DetailSBAR from '@/components/Ranap/DetailSBAR.vue'  
  import DetailSBARAdvis from "@/components/Ranap/DetailSBARAdvis.vue"

  export default {
    extends: GlobalVue,
    data() {
      return {
        rowReg: {},
        listCPPT: [],
  			checkAll: false,
        patientData: {},
        loading: {
          patientInfo: false,
        },
        openDetailSBAR: false,
        dataSBAR: {},
        filterPPA: 'ALL',
        filterPPAData: [
          {
            text: "Semua PPA",
            value: "ALL"
          },
          {
            text: "CPPT Perawat",
            value: "PERAWAT"
          },
          {
            text: "CPPT SBAR",
            value: "SBAR"
          },
          {
            text: "CPPT DOKTER",
            value: "DOKTER"
          },
          {
            text: "CPPT PPA Radiologi",
            value: "PPARADIO"
          },
          {
            text: "CPPT PPA Laboratorium",
            value: "PPALAB"
          },
          {
            text: "CPPT PPA Farmasi",
            value: "PPAFARMASI"
          },
          {
            text: "CPPT PPA Fisioterapi",
            value: "PPAFISIO"
          },
          {
            text: "CPPT PPA Dietisien",
            value: "DIETISIEN"
          },
        ],

        openDetailSBARAdvis: false,
        dataSBARAdvis: {},
        dataBulkVerifFP: [],
        openVerifFP: false,
        statusFP: '',
      }
    },
    
    components: {
      PatientInfo,DetailSBAR,DetailSBARAdvis
    },

    computed: {
      selectedInput(){
        let selected = []
        for(let i = 0; i < (this.listCPPT||[]).length; i++){
          if(this.listCPPT[i]['selected'] && this.listCPPT[i]['arancp_is_verif'] != 'Y'){
            selected.push(this.listCPPT[i]['arancp_id'])
          }
        }
        return selected
      },
      isShowSBAR(){
        if(this.user.levelId == this.uDokter || this.user.levelId == this.uDokterFisio || this.user.levelId == this.uDokterUGD){
          // tanda dokter umum
          if(this.user.mpo_id == 32){
            return true
          }else{
            return false
          }
        }else{
          return true
        }
      }
    },

    methods: {
      
      
      initFP(){
        let self = this
        let currentFormat = window.Fingerprint.SampleFormat.Intermediate
        let FingerprintSdkTest = (function () {
          function FingerprintSdkTest() {
              this.operationToRestart = null
              this.acquisitionStarted = false
              // instantiating the fingerprint sdk here
              this.sdk = new window.Fingerprint.WebApi

              this.sdk.onDeviceConnected = function (e) {
                  console.log("connect")
              }
              this.sdk.onDeviceDisconnected = function (e) {
                  // Detects if device gets disconnected - provides deviceUid of disconnected device
                  console.log("disconnect")
              }
              this.sdk.onCommunicationFailed = function (e) {
                  // Detects if there is a failure in communicating with U.R.U web SDK
                  console.log("failure")
              }
              this.sdk.onSamplesAcquired = function (s) {
                  self.storeSample(s)
              }
          }

          // this is were finger print capture takes place
          FingerprintSdkTest.prototype.startCapture = function () {
              if (this.acquisitionStarted) // Monitoring if already started capturing
                  return
              let _instance = this
              this.operationToRestart = this.startCapture
              this.sdk.startAcquisition(currentFormat, "").then(function () {
                  _instance.acquisitionStarted = true
              }, function (error) {
                console.log(error)
              })
          }
          
          FingerprintSdkTest.prototype.stopCapture = function () {
              if (!this.acquisitionStarted) //Monitor if already stopped capturing
                  return
              let _instance = this
              this.sdk.stopAcquisition().then(function () {
                  _instance.acquisitionStarted = false
              }, function (error) {
                console.log(error)
              })
          }
          return FingerprintSdkTest
        })()

        class Reader{
          constructor(){
              this.reader = new FingerprintSdkTest()
              this.selectFieldID = null
              this.currentStatusField = null
              /**
               * @type {Hand}
               */
              this.currentHand = null
          }
        }

        let myReader = new Reader()
        myReader.reader.startCapture()
      },

      toDetailSBARAdvis(v){
        this.openDetailSBARAdvis = true
        this.dataSBARAdvis = v
      },
      isHaveTindakLanjut(v){
        if(v.arancsa_is_resep == "Y" || v.arancsa_is_lab == "Y" || v.arancsa_is_radiologi == "Y" || v.arancsa_is_fisio == "Y" || v.arancsa_is_operasi == "Y" || v.arancsa_is_konsultasi == "Y" || v.arancsa_is_dietisien == "Y" || v.arancsa_is_raber == "Y" || v.arancsa_is_alih_rawat == "Y" || v.arancsa_is_pindah_ruang == "Y" || v.arancsa_is_perawatan == "Y" || v.arancsa_is_done_perawatan == "Y" || v.arancsa_is_rencana_operasi == "Y" || v.arancsa_is_stop_raber == 'Y'
        ){
          return true
        }else{
          return false
        }
      },
      toDetailSBAR(v){
        let data = {
          type: "detail-sbar",
          id: v.arancp_id
        }
        this.loadingOverlay = true
        Gen.apiRest(
            "/do/"+'RanapCPPT',
            {data: data}, 
            "POST"
        ).then(res=>{
          this.openDetailSBAR = true        
          this.loadingOverlay = false
          this.dataSBAR = res.data
        })
      },
      getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
          "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.params.pageSlug
            }
          },
          "POST"
        ).then(resp => {
          this.$set(this, 'patientData', resp.data.data)

          this.$set(this.loading, 'patientInfo', false)
        })
      },

      defineAnat(anat){
        if(anat == 'eye'){
          return "Mata"
        }else if(anat == 'front-eye'){
          return "Mata Depan"
        }else if(anat == 'odontogram'){
          return "Odontogram"
        }else if(anat == 'nervousSystem'){
          return "Syaraf"
        }else if(anat == 'heart'){
          return "Jantung"
        }else if(anat == 'ear'){
          return "Telinga"
        }else if(anat == 'nose'){
          return "Hidung"
        }else if(anat == 'throat'){
          return "Tenggorokkan"
        }else if(anat == 'lung'){
          return "Paru-Paru"
        }else if(anat == 'joint'){
          return "Sendi"
        }else{
          return "Tubuh"
        }
      },
      checkTindakLanjut(data){
        let TL = []
        if(data.arancpd_is_resep == 'Y'){
          TL.push('Resep')
        }
        if(data.arancpd_is_lab == 'Y'){
          TL.push('Laboratorium')
        }
        if(data.arancpd_is_radiologi == 'Y'){
          TL.push('Radiologi')
        }
        if(data.arancpd_is_fisio == 'Y'){
          TL.push('Fisioterapi')
        }
        if(data.arancpd_is_operasi == 'Y'){
          TL.push('Tindakan Operasi')
        }
        if(data.arancpd_is_konsultasi == 'Y'){
          TL.push('Konsultasi')
        }
        if(data.arancpd_is_dietisien == 'Y'){
          TL.push('Konsultasi Dietisien')
        }
        if(data.arancpd_is_raber == 'Y'){
          TL.push('Rawat Bersama')
        }
        if(data.arancpd_is_alih_rawat == 'Y'){
          TL.push('Alih Rawat')
        }
        if(data.arancpd_is_pindah_ruang == 'Y'){
          TL.push('Pindah Ruang')
        }
        if (data.arancpd_is_rencana_operasi == 'Y') {
          TL.push('Rencanakan Operasi')
        }
        if (data.arancpd_is_stop_raber == 'Y') {
          TL.push('Stop Rawat Bersama')
        }
        if(data.arancpd_is_perawatan == 'Y'){
          TL.push('Masih dalam Perawatan')
        }
        if(data.arancpd_is_done_perawat == 'Y'){
          TL.push('Selesai Perawatan')
        }
        return TL.join(", ")
      },
      checkDataRow(){
        // setTimeout(()=>{
          let totalAll = (this.listCPPT||[]).length
          let selected = 0
          for(let i = 0; i < (this.listCPPT||[]).length; i++){
            if(this.listCPPT[i]['selected']){
              selected += 1
            }
          }
          if(selected == totalAll){
            this.checkAll = true
          }else{
            this.checkAll = false
          }
        // },250)
      },	
      checkData(e,isChecked=false){
        if(this.checkAll && isChecked){
          for(let i = 0; i < (this.listCPPT||[]).length; i++){
            this.listCPPT[i]['selected'] = true
          }
        }else if(!this.checkAll && isChecked){
          for(let i = 0; i < (this.listCPPT||[]).length; i++){
            this.listCPPT[i]['selected'] = false
          }
        }
      },

      singleVerif(v){
        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan memverifikasi data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
            if (result.value) {
              let data = {
                type: "verif-cppt",
                id: v.arancp_id
              }
              this.loadingOverlay = true
              Gen.apiRest(
                  "/do/"+this.modulePage,
                  {data: data}, 
                  "POST"
              ).then(res=>{
                  this.$swal({
                    title: 'Berhasil Memverifikasi Data',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  })
                  this.checkAll = false
                  this.loadingOverlay = false
                  this.apiGet()
              })
            }
        })
      },
      initbulkVerif(){
        // super mah bypass aja
        if(this.user.levelId == 1){
          this.bulkVerif()
        }else{
          // check settings
          this.loadingOverlay = true
          let dataPost = {type : 'check-settings-verif'}
          Gen.apiRest(
              "/do/"+this.modulePage,
              {data: dataPost}, 
              "POST"
          ).then(res=>{
            if(res.data){
              this.dataBulkVerifFP = this.selectedInput
              this.openVerifFP = true
              this.statusFP = ''
              this.initFP()
              this.loadingOverlay = false
            }else{
              this.loadingOverlay = false
              this.bulkVerif()
            }
          })
        }
      },
      initsingleVerif(v){
        // super mah bypass aja
        if(this.user.levelId == 1){
          this.singleVerif(v)
        }else{
          // check settings
          // check settings
          this.loadingOverlay = true
          let dataPost = {type : 'check-settings-verif'}
          Gen.apiRest(
              "/do/"+this.modulePage,
              {data: dataPost}, 
              "POST"
          ).then(res=>{
            if(res.data){
              this.dataBulkVerifFP = [v.arancp_id]
              this.openVerifFP = true
              this.statusFP = ''
              this.initFP()
              this.loadingOverlay = false
            }else{
              this.loadingOverlay = false
              this.singleVerif(v)
            }
          })

        }
      },
      bulkVerif(){
        this.$swal({
          icon: 'warning',
          title: 'Apakah Item yang Anda pilih sudah benar, dan yakin ingin memverifikasi data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
          if (result.value) {
            let data = {
              type: "verif-cppt-bulk",
              id: this.selectedInput
            }
            this.loadingOverlay = true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                this.$swal({
                  title: 'Berhasil Memverifikasi Data',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                this.checkAll = false
                this.loadingOverlay = false
                this.dataBulkVerifFP = []
                this.apiGet()
            })
          }
        })
      },
      deleteCPPT(v){
        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menghapus data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
          if (result.value) {
            let data = {
              type: "delete-cppt",
              id: v.arancp_id
            }
            this.loadingOverlay = true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                this.$swal({
                  title: 'Berhasil Menghapus Data',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                this.loadingOverlay = false
                this.apiGet()
            })
          }
        })
        
      },
      getUserInput(type){
        let text = ''
        if(type == 'DOKTER'){
          text = 'Dokter'
        }else if(type == 'PPALAB'){
          text = 'Laboratorium'
        }else if(type == 'PPARADIO'){
          text = 'Radiologi'
        }else if(type == 'PPAFISIO'){
          text = 'Fisioterapi'
        }else if(type == 'PPAFARMASI'){
          text = 'Farmasi'
        }else if(type == 'DIETISIEN'){
          text = 'Dietisien'
        }else if(type == 'PERAWAT'){
          text = 'Perawat'
        }else {
          text = null
        }
        return text
      },
      addNewCPPT(type = ''){
        this.loadingOverlay = true
        let data = {
            idPasien : this.rowReg.aranr_ap_id,
            regId : this.rowReg.aranr_id,
            jenisCPPT: type,
            konsulId: this.$route.query.konsulId,
            raberId: this.$route.query.raberId,
            dpjpId: this.rowReg.aranr_dokter_id 
        }
        data.type = 'init-cppt'
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
          let resp = res.data
          this.loadingOverlay = false
          this.$router.push({ name: resp.menuCPPT, params: {pageSlug: this.rowReg.aranr_id, cpptNo: resp.arancp_id} })
          .catch(()=>{})
        }).catch(err => {
          this.loadingOverlay = false
          if (err) {
            err.statusType = err.status
            err.status = "error"
            err.title = err.response?.data?.title
            err.message = err.response?.data?.message
            err.messageError = err.message
          }
          this.doSetAlertForm(err)
        })

      },

      toEdit(row,menu){
        this.$router.push({ name: menu, params: {pageSlug: this.rowReg.aranr_id, cpptNo: row.arancp_id}}).catch(()=>{})
      },

      back() {
        if(this.user.levelId == this.uDokter || this.user.levelId == this.uDokterFisio || this.user.levelId == this.uDokterUGD){
          this.$router.push({name : 'RanapPasienPerawatanDPJP'})
        }else{
          this.$router.push({name : 'RanapPasienPerawatan'})
        }
      },
      
      getConfigDynamic(master,value){
          let text = ''
          if(value){
              let index = (master||[]).findIndex(x => x.value == value)
              if(index !== -1){
                  text = master[index]['text']
              }
          }
          return text
      },

      apiGet(params = {}, page = 1) {
        if (!this.pageSlug) {
          this.$router.push({
            name: 'Dashboard'
          }).catch(() => {})
        }

        if (this.pageSlug || this.$route.name == 'Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.modulePage + '/' + this.$route.params.pageSlug

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: page
            }, {typePPA : this.filterPPA})
          }
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          this.pageNow = page
          this.last_page = this.data.last_page

          if (!this.isFound) {
            this.$router.push({
              name: 'Dashboard'
            }).catch(() => {})
          }
        }).catch(() => {
          this.loadingOverlay = false
        })

        // master
        if (this.$route.params.rmNo) {
          Gen.apiRest(
            "/get/" + url + '?master=1', {
              params: Object.assign({
                page: page
              }, paramsQuery, params.query || {})
            }
          ).then(res => {
            _.forEach(res.data, (v, k) => {
              this.$set(this, k, v)
            })
          })
        }

      },

      filteringPPA(){
        this.apiGet()
      },


      storeSample(sample){
        let samples = JSON.parse(sample.samples)
        let sampleData = samples[0].Data
        let verifyFinger = sampleData  
        console.log(verifyFinger)
        
        let id = this.user.id
        let data = {id, verifyFinger}
        
        if(this.openVerifFP){
          let postData = {
            data : data,
            id: id,
            type : "verify"
          }
          
          this.loadingOverlay = true

          Gen.apiRest(
            "/do/"+'FingerPrint', 
            {
              data: postData
            }, "POST"
          ).then(res=>{
            let data = {
              type: "verif-cppt-bulk",
              id: this.dataBulkVerifFP
            }
            this.loadingOverlay = true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
              this.loadingOverlay = false
              this.statusFP = 'success'
              this.checkAll = false
              this.dataBulkVerifFP = []
              this.apiGet()

              // setTimeout(() => {
              //   this.openVerifFP = false
              // }, 2000)
            }).catch(()=>{
              this.statusFP = 'fail'
              this.loadingOverlay = false
            })
            // this.statusFP = 'success'
          }).catch(()=>{
            this.statusFP = 'fail'
            this.loadingOverlay = false
            console.log("gagal sini")
          })
        }

      }

    },
    mounted() {
      this.apiGet()
      this.getPatientInfo()
    },
  }
</script>